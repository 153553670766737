import {
  //collections
  strapiGraphqlQueryPageCollection,
  strapiGraphqlQueryResourcePageCollection,
  strapiGraphqlQueryResourceLinkCollection,
  //components
  strapiGraphqlQueryColumnCardsComponent,
  strapiGraphqlQueryAccordionComponent,
  strapiGraphqlQueryMegaAccordionComponent,
  strapiGraphqlQueryTwoColumnAccordionComponent,
  strapiGraphqlQueryIconBannerComponent,
  deprecatedStrapiGraphqlQueryStandardHeroComponent,
  strapiGraphqlQueryTabbedNavComponent,
  strapiGraphqlQueryColorDividerComponent,
  strapiGraphqlQueryTwoColumnLayout,
  strapiGraphqlQueryConnectwithExpert,
  strapiGraphqlQueryIntro2Column,
  strapiGraphqlQueryVideo,
  strapiGraphqlQueryColumnCardsBGImageComponent,
  strapiGraphqlQueryTriCards,
  strapiGraphqlQueryStandardHeroComponent,
  strapiGraphqlQueryH1IntroText,
  strapiGraphqlQueryTabbedCarouselComponent,
  strapiGraphqlQueryFeaturedCarousel,
  strapiGraphqlQueryH1Infographic,
  strapiGraphqlQueryH1IntroSingleColumn,
  strapiGraphqlQueryH1IntroThreeColumn,
  strapiGraphqlQueryTextTwoColumnList,
  strapiGraphqlQueryPhotoCollage,
  strapiGraphqlQueryFocusCarousel,
  strapiGraphqlQueryThreeColumnCarousel,
  strapiGraphqlQueryFiveColumnBioCards,
  strapiGraphqlQueryFilteredList,
  strapiGraphqlQueryAlgoliaFilteredList,
  strapiGraphqlQueryResourceCard,
  strapiGraphqlQueryImageCardCarousel5050,
  strapiGraphqlQueryH1ImageCarousel,
  strapiGraphqlQueryThreeColumnList,
  strapiGraphqlQueryNewsCarousel,
  strapiGraphqlQueryListView,
  strapiGraphqlQueryResourceList,
  strapiGraphqlQueryCalendar,
  strapiGraphqlQueryCtaImageScrollerCarousel,
  strapiGraphqlQueryTwoColumnListWithIcons,
  strapiGraphqlQuerySixtyFortyFundType,
  strapiGraphqlQueryTableAccordion,
  strapiGraphqlFourColumnTable,
  strapiGraphqlListViewCalendar,
  strapiGraphqlFourRowIcon,
} from "shared/lib/strapi/graphql/queries";

const componentMap = {
  Accordion: strapiGraphqlQueryAccordionComponent,
  MegaAccordion: strapiGraphqlQueryMegaAccordionComponent,
  TwoColumnAccordion: strapiGraphqlQueryTwoColumnAccordionComponent,
  IconBanner: strapiGraphqlQueryIconBannerComponent,
  DeprecatedStandardHero: deprecatedStrapiGraphqlQueryStandardHeroComponent,
  TabbedNav: strapiGraphqlQueryTabbedNavComponent,
  ColorDivider: strapiGraphqlQueryColorDividerComponent,
  ImageBlockNew: strapiGraphqlQueryTwoColumnLayout,
  ColumnCards: strapiGraphqlQueryColumnCardsComponent,
  ConnectWithExpert: strapiGraphqlQueryConnectwithExpert,
  TextIntroTwoColumn: strapiGraphqlQueryIntro2Column,
  Video: strapiGraphqlQueryVideo,
  ImageCardColumn: strapiGraphqlQueryColumnCardsBGImageComponent,
  TriCards: strapiGraphqlQueryTriCards,
  StandardHero: strapiGraphqlQueryStandardHeroComponent,
  IntroH1: strapiGraphqlQueryH1IntroText,
  TabbedCarousel: strapiGraphqlQueryTabbedCarouselComponent,
  FeaturedCards: strapiGraphqlQueryFeaturedCarousel,
  InfographicStandard: strapiGraphqlQueryH1Infographic,
  H1TextIntroOneColumn: strapiGraphqlQueryH1IntroSingleColumn,
  IntroThreeColumn: strapiGraphqlQueryH1IntroThreeColumn,
  TextTwoColumnList: strapiGraphqlQueryTextTwoColumnList,
  PhotoCollage: strapiGraphqlQueryPhotoCollage,
  FocusCarousel: strapiGraphqlQueryFocusCarousel,
  ThreeColumnImageCarousel: strapiGraphqlQueryThreeColumnCarousel,
  OurTeamCards: strapiGraphqlQueryFiveColumnBioCards,
  FilteredList: strapiGraphqlQueryFilteredList,
  AlgoliaFilteredList: strapiGraphqlQueryAlgoliaFilteredList,
  ResourceCard: strapiGraphqlQueryResourceCard,
  ImageCarousel5050: strapiGraphqlQueryImageCardCarousel5050,
  H1ImageCarousel: strapiGraphqlQueryH1ImageCarousel,
  ColumnListContainer: strapiGraphqlQueryThreeColumnList,
  NewsCardCarousel: strapiGraphqlQueryNewsCarousel,
  ListView: strapiGraphqlQueryListView,
  ResourceList: strapiGraphqlQueryResourceList,
  Calendar: strapiGraphqlQueryCalendar,
  ImageScrollerCarousel: strapiGraphqlQueryCtaImageScrollerCarousel,
  TwoColumnListWithIcon: strapiGraphqlQueryTwoColumnListWithIcons,
  SixtyFortyFundType: strapiGraphqlQuerySixtyFortyFundType,
  TableAccordion: strapiGraphqlQueryTableAccordion,
  FourColumnTable: strapiGraphqlFourColumnTable,
  ListViewCalendar: strapiGraphqlListViewCalendar,
  FourRowIcon: strapiGraphqlFourRowIcon,
}

export type ComponentName = keyof typeof componentMap;
export type CollectionName = keyof typeof map;

interface CollectionEntry {
  id: string;
  queries: {
    page: any;
    components: ReturnType<typeof mapComponents>;
  };
  filterBy?: { keywords: string[] };
}

const mapComponents = (componentNames: ComponentName[]) => {
  return componentNames.reduce((accumulator, componentName) => {
    const component = componentMap[componentName];
    if (component) {
      accumulator[componentName] = component;
    } else {
      console.warn(`Component "${componentName}" not found in componentMap.`);
    }
    return accumulator;
  }, {} as { [K in ComponentName]?: typeof componentMap[K] });
};


const defaultFilterBy = { keywords: ['title', 'slug'] };

const resourcePages = {
  queries: {
    page: strapiGraphqlQueryResourcePageCollection, // page query
    components: mapComponents(['Video', 'StandardHero', 'FilteredList', 'ResourceCard'])
  },
  filterBy: {
    keywords: ['title', 'slug', 'description'], // Based off the page query
  }
}

const map = {
  "Video Pages": { ...resourcePages, id: 'videoPages' },
  "Article Pages": { ...resourcePages, id: 'articlePages' },
  "Resource Links": {
    id: 'resourceLinks',
    queries: {
      page: strapiGraphqlQueryResourceLinkCollection
    },
    filterBy: {
      keywords: ['title', 'link.href', 'description'],
    }
  },
  "Discover Pages": {
    id: 'discoverPages',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents([
        'Accordion', 'MegaAccordion', 'TwoColumnAccordion', 'IconBanner', 'DeprecatedStandardHero',
        'TabbedNav', 'ColorDivider', 'ImageBlockNew', 'ColumnCards', 'ConnectWithExpert',
        'TextIntroTwoColumn', 'Video', 'ImageCardColumn', 'TriCards', 'StandardHero',
        'IntroH1', 'TabbedCarousel', 'FeaturedCards', 'InfographicStandard', 'H1TextIntroOneColumn',
        'IntroThreeColumn', 'TextTwoColumnList', 'PhotoCollage', 'FocusCarousel', 'ThreeColumnImageCarousel',
        'OurTeamCards', 'ImageCarousel5050', 'H1ImageCarousel', 'ColumnListContainer', 'NewsCardCarousel', 'ListView', 'ResourceList', 'Calendar',
        'SixtyFortyFundType',
        'FourColumnTable',
        'ListViewCalendar',
        'SixtyFortyFundType', 'FourColumnTable', 'FourRowIcon'
      ])
    }
  },
  "Receive Pages": {
    id: 'receivePages',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents(['FilteredList', 'Accordion', 'MegaAccordion', 'TwoColumnAccordion', 'IconBanner', 'DeprecatedStandardHero',
        'TabbedNav', 'ColorDivider', 'ImageBlockNew', 'ColumnCards', 'ConnectWithExpert',
        'TextIntroTwoColumn', 'Video', 'ImageCardColumn', 'TriCards', 'StandardHero',
        'IntroH1', 'TabbedCarousel', 'FeaturedCards', 'InfographicStandard', 'H1TextIntroOneColumn',
        'IntroThreeColumn', 'TextTwoColumnList', 'PhotoCollage', 'FocusCarousel', 'ThreeColumnImageCarousel',
        'OurTeamCards', 'ImageCarousel5050', 'H1ImageCarousel', 'ColumnListContainer', 'NewsCardCarousel', 'ListView', 'FourColumnTable', 'SixtyFortyFundType',
        'FourRowIcon', 'ListViewCalendar', 'ResourceList'])
    }
  },
  "Engage Pages": {
    id: 'engagePages',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents(['FilteredList', 'Accordion', 'MegaAccordion', 'TwoColumnAccordion', 'IconBanner', 'DeprecatedStandardHero',
        'TabbedNav', 'ColorDivider', 'ImageBlockNew', 'ColumnCards', 'ConnectWithExpert',
        'TextIntroTwoColumn', 'Video', 'ImageCardColumn', 'TriCards', 'StandardHero',
        'IntroH1', 'TabbedCarousel', 'FeaturedCards', 'InfographicStandard', 'H1TextIntroOneColumn',
        'IntroThreeColumn', 'TextTwoColumnList', 'PhotoCollage', 'FocusCarousel', 'ThreeColumnImageCarousel',
        'OurTeamCards', 'ImageCarousel5050', 'H1ImageCarousel', 'ColumnListContainer', 'NewsCardCarousel', 'ListView', 'ResourceList', 'FourColumnTable', 'SixtyFortyFundType', 'FourRowIcon', 'ListViewCalendar'])
    }
  },
  "Give Pages": {
    id: 'givePages',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents(['FilteredList', 'Accordion', 'MegaAccordion', 'TwoColumnAccordion', 'IconBanner', 'DeprecatedStandardHero',
        'TabbedNav', 'ColorDivider', 'ImageBlockNew', 'ColumnCards', 'ConnectWithExpert',
        'TextIntroTwoColumn', 'Video', 'ImageCardColumn', 'TriCards', 'StandardHero',
        'IntroH1', 'TabbedCarousel', 'FeaturedCards', 'InfographicStandard', 'H1TextIntroOneColumn',
        'IntroThreeColumn', 'TextTwoColumnList', 'PhotoCollage', 'FocusCarousel', 'ThreeColumnImageCarousel',
        'OurTeamCards', 'ImageCarousel5050', 'H1ImageCarousel', 'ColumnListContainer', 'NewsCardCarousel', 'ListView', 'ImageScrollerCarousel',
        'TwoColumnListWithIcon', 'TableAccordion', 'FourColumnTable', 'SixtyFortyFundType', 'FourRowIcon', 'ListViewCalendar', 'ResourceList'])
    }
  },
  "In The News": {
    id: 'newsPages',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents(['FilteredList', 'H1TextIntroOneColumn', 'StandardHero', 'ImageBlockNew', 'Video', 'NewsCardCarousel', 'ListView', 'TextIntroTwoColumn', 'IntroThreeColumn', 'IntroH1', 'InfographicStandard', 'FourRowIcon', 'ListViewCalendar', 'ResourceList'])
    }
  },
  "Blog": {
    id: 'blogs',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents(['FilteredList', 'H1TextIntroOneColumn', 'StandardHero', 'ImageBlockNew', 'Video', 'NewsCardCarousel', 'ListView', 'TextIntroTwoColumn', 'IntroThreeColumn', 'IntroH1', 'InfographicStandard', 'FourRowIcon', 'ListViewCalendar', 'ResourceList'])
    }
  },
  "Special Events": {
    id: 'specialEvents',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents(['FilteredList', 'Accordion', 'MegaAccordion', 'TwoColumnAccordion', 'IconBanner', 'DeprecatedStandardHero',
        'TabbedNav', 'ColorDivider', 'ImageBlockNew', 'ColumnCards', 'ConnectWithExpert',
        'TextIntroTwoColumn', 'Video', 'ImageCardColumn', 'TriCards', 'StandardHero',
        'IntroH1', 'TabbedCarousel', 'FeaturedCards', 'InfographicStandard', 'H1TextIntroOneColumn',
        'IntroThreeColumn', 'TextTwoColumnList', 'PhotoCollage', 'FocusCarousel', 'ThreeColumnImageCarousel',
        'OurTeamCards', 'ImageCarousel5050', 'H1ImageCarousel', 'ColumnListContainer', 'NewsCardCarousel', 'FourRowIcon', 'ListViewCalendar', 'ResourceList'
      ])
    }
  },
  "Other Pages": {
    id: 'otherPages',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents(['InfographicStandard', 'ImageCardColumn', 'MegaAccordion', 'Video', 'ImageBlockNew', 'TwoColumnAccordion', 'ColorDivider', 'TabbedNav', 'FocusCarousel', 'ConnectWithExpert', 'TextTwoColumnList', 'ColumnCards', 'PhotoCollage', 'ColumnListContainer', 'TriCards', 'Accordion', 'IntroThreeColumn', 'ImageCarousel5050', 'DeprecatedStandardHero', 'OurTeamCards', 'IconBanner', 'NewsCardCarousel', 'TextIntroTwoColumn', 'H1ImageCarousel', 'FilteredList', 'AlgoliaFilteredList', 'IntroH1', 'H1TextIntroOneColumn', 'TabbedCarousel', 'StandardHero', 'ThreeColumnImageCarousel', 'FeaturedCards', 'FourColumnTable', 'SixtyFortyFundType', 'FourRowIcon', 'ListViewCalendar', 'ResourceList'])
    }
  },
  "Alerts": {
    id: 'alertsPages',
    queries: {
      page: strapiGraphqlQueryPageCollection,
      components: mapComponents([
        'Accordion', 'MegaAccordion', 'TwoColumnAccordion', 'IconBanner', 'DeprecatedStandardHero',
        'TabbedNav', 'ColorDivider', 'ImageBlockNew', 'ColumnCards', 'ConnectWithExpert',
        'TextIntroTwoColumn', 'Video', 'ImageCardColumn', 'TriCards', 'StandardHero',
        'IntroH1', 'TabbedCarousel', 'FeaturedCards', 'InfographicStandard', 'H1TextIntroOneColumn',
        'IntroThreeColumn', 'TextTwoColumnList', 'PhotoCollage', 'FocusCarousel', 'ThreeColumnImageCarousel',
        'OurTeamCards', 'ImageCarousel5050', 'H1ImageCarousel', 'ColumnListContainer', 'NewsCardCarousel', 'FourRowIcon', 'ListViewCalendar', 'ResourceList'
      ])
    }
  },
};

const collectionQueryMap = (collections: CollectionName[]) => {
  const result = collections.reduce((accumulator, collection) => {
    const collectionData = map[collection] as CollectionEntry;
    if (collectionData) {
      // Check if collectionData has a filterBy property
      if ('filterBy' in collectionData) {
        accumulator[collection] = { ...collectionData };
      } else {
        accumulator[collection] = {
          ...collectionData,
          filterBy: defaultFilterBy,
        };
      }
    } else {
      console.warn(`Collection "${collection}" not found.`);
    }
    return accumulator;
  }, {} as Record<CollectionName, CollectionEntry>);

  if (Object.keys(result).length === 0) {
    throw new Error('No valid collections found');
  }

  return result;
}

export { collectionQueryMap };
