import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorMap } from './Calendar';
import { EventDetails } from './EventDetails';

interface EventCardProps {
  event: any;
  titleAttribute: string;
  startDTAttribute: string;
  endDTAttribute: string;
  colorMapAttribute: string;
  colorMap: ColorMap[];
  className: string;
}

export const EventCard: React.FC<EventCardProps> = ({
  event,
  titleAttribute,
  startDTAttribute,
  endDTAttribute,
  colorMapAttribute,
  colorMap,
  className,
}) => {
  const startDate = dayjs(event[startDTAttribute]).format('MM/DD/YYYY');
  const startTime = dayjs(event[startDTAttribute]).format('h:mm A');
  const endTime = dayjs(event[endDTAttribute]).format('h:mm A');

  // Find the colorMap key for this event
  const colorMapKey = colorMap.find((map) => map.key === event[colorMapAttribute])?.key;

  return (
    <div className={`event-cards__card`}>
      <div className={`event-cards__card__title ${className}`}>
        <h2> <FontAwesomeIcon icon="calendar-days" /> {colorMapKey}</h2>
      </div>
      <EventDetails
        title={event[titleAttribute] || 'N/A'}
        startDate={startDate}
        startTime={startTime}
        endTime={endTime}
        event={event}
        className={className}
      />
    </div>
  );
};