import React from 'react';
import { Button } from 'components/Buttons';

interface EventDetailsProps {
  title: string;
  startDate: string;
  startTime: string;
  endTime: string;
  event: any;
  className: string;
}

export const EventDetails: React.FC<EventDetailsProps> = ({
  title,
  startDate,
  startTime,
  endTime,
  event,
  className,
}) => {

  // Top section
  const duration = event["event_duration"] || "N/A";
  const audience = event["event_audience"] || "N/A";
  const eventType = event["event_type"] || "N/A";

  // Mid Section
  const host = event["organization__r.name"] || "N/A";
  const cost = event.cost == null || event.cost === '0.0' ? "Free" : `{$${event.cost}}`;
  const eventStyle = event["event_style"] || "N/A";

  // Bottom Section
  const address = event["event_location"] || "N/A";
  const contact = event["primary_inquiry_name"] || "N/A";
  const phoneNumber = event["cfr_primary_inquiry_phone_number"] || "N/A";
  const emailAddress = event["primary_inquiry_email"] || "N/A";

  // Button Section
  const buttonLink = event["website"];

  return (
    <>
      <div className='event-cards__card__top-section'>
        <h2>{title}</h2>
        <p><strong>Date:</strong> {startDate}, {startTime} - {endTime}</p>
        <p><strong>Duration:</strong> {duration}</p>
        <p><strong>Audience:</strong> {audience}</p>
        <p><strong>Event Type:</strong> {eventType}</p>
      </div>
      <div className={`event-cards__card__mid-section ${className}`}>
        <p><strong>Host:</strong> {host}</p>
        <p><strong>Cost:</strong> {cost}</p>
        <p><strong>Event Style:</strong> {eventStyle}</p>
      </div>
      <div className='event-cards__card__bottom-section'>
        <p><strong>Address:</strong> {address}</p>
        <p><strong>Contact:</strong> {contact}</p>
        <p><strong>Phone:</strong> {phoneNumber}</p>
        <p><strong>Email:</strong> {emailAddress}</p>
      </div>
      <div className='event-cards__card__button-section'>
        <Button
          text="Learn More Or Register"
          customClassName='button--featured-events'
          link={buttonLink}
          openInNewTab={true}
        />
      </div>
    </>
  );
};